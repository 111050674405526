<template>
  <section>
    <div class="background">
      <img class="img" src="../../../assets/zh/images/jihua@2x.png" alt="" />
      <div class="button">
        <el-button @click="GoCooVally()"> 立即体验 </el-button>
      </div>
    </div>
    <FooterC></FooterC>
  </section>
</template>
      
<script>
import FooterC from "../components/FooterC.vue";
export default {
  name: "Home",
  components: {
    FooterC,
  },

  methods: {
    GoCooVally() {
      let url = Global.COOVALLY_URL;
      window.open(url, "_blank");
    },
  },
};
</script>
    
<style lang="scss" scoped>
.background {
  width: 100%;
  // min-width: 1920px;
  // min-height: 3500px;
  //   height: 3575px;
  // background-image: url("../../../assets/zh/images/jihua@2x.png");
  // background-size: 1920px 3575px;
  // background-repeat: no-repeat;
  img {
    width: 100%;
  }
  .button {
    position: absolute;
    // top:410px;
    // left:870px;
    top: 43vh;
    left: 44%;

    /deep/.el-button {
      width: 20vh;
      height: 7vh;
      background: linear-gradient(180deg, #abdafb 0%, #8bc4fa 100%);
      box-shadow: 0px 11px 24px 0px rgba(0, 82, 194, 0.27);
      border-radius: 11px;
      border: 2px solid rgba(255, 255, 255, 0.74);
      position: relative;
      bottom: 0;
      transition: all 200ms linear;
      -webkit-transition: all 200ms linear;
      span {
        font-size: 3vh;
        font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
        font-weight: 600;
        color: #1839b8;
        line-height: 3vh;
      }
      &:hover {
        bottom: 6px;
        box-shadow: 0px 8px 14px 0px rgba(52, 62, 76, 0.08);
      }
    }

    /deep/.el-button:hover {
      background: linear-gradient(180deg, #abdafb 0%, #8bc4fa 100%);
      opacity: 0.8;
    }
  }
}
</style>